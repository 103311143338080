<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Jaime Javier Coronado Guerrero                                  ###### -->
<!-- ###### @date: Octubre 2024                                                 ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <div class="contenido">
      <!-- Fitros -->
      <section>
        <div class="d-flex">
          <!-- Filtrar por fecha inicial -->
          <v-menu ref="menuFechaInicio" v-model="menuFechaInicio" transition="scale-transition" min-width="290px"
            :close-on-content-click="false" :return-value.sync="filtro.fechaInicio" :nudge-top="25" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field class="me-2 filtro" v-model="filtro.fechaInicio" label="Fecha inicial *" v-bind="attrs" v-on="on"
                outlined dense readonly>
              </v-text-field>
            </template>
            <v-date-picker v-model="filtro.fechaInicio" :first-day-of-week="1" :max="currentDate" locale="es-co"
              no-title scrollable @input="saveFechaInicial">
              <v-btn text color="primary" @click="cleanFechaInicial">Borrar</v-btn>
            </v-date-picker>
          </v-menu>
  
          <!-- Filtrar por fecha final -->
          <v-menu ref="menuFechaFinal" v-model="menuFechaFinal" transition="scale-transition" min-width="290px"
            :close-on-content-click="false" :return-value.sync="filtro.fechaFin" :nudge-top="25" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field class="me-2 filtro" v-model="filtro.fechaFin" label="Fecha final *" v-bind="attrs" v-on="on"
                :error-messages="rangoFechas" outlined dense readonly>
              </v-text-field>
            </template>
            <v-date-picker v-model="filtro.fechaFin" :first-day-of-week="1" :max="currentDate" locale="es-co" no-title
              scrollable @input="saveFechaFinal">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="cleanFechaFinal">Borrar</v-btn>
            </v-date-picker>
          </v-menu>
  
          <!-- Botón para generar reporte -->
          <v-tooltip left :color="permitirGenerarReporte ? 'primary' : 'grey'" :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn small fab :color="permitirGenerarReporte ? 'primary' : 'grey'" 
                v-bind="attrs" v-on="on" @click="permitirGenerarReporte && generarReporte()">
                <v-icon color="white"> download </v-icon>
              </v-btn>
            </template>
            <span>Generar reporte</span>
          </v-tooltip>
        </div>
      </section>
  
      <!-- Tabla que almacena las solicitudes -->
      <v-data-table class="elevation" :items="tablaDespachos.items" :loading="tablaDespachos.loading"
        fixed-header :headers="tablaDespachos.headers" :footer-props="tablaDespachos.footerProps"
        :items-per-page.sync="tablaDespachos.itemsPerPage" :page.sync="tablaDespachos.page"
        :server-items-length="tablaDespachos.totalPage" height="64vh">
        <template v-slot:footer.page-text="items">
          {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
        </template>
      </v-data-table>
  
    </div>
  </template>
  <!-- #################################################################################### -->
  <!-- ###### Sección de Scripts                                                     ###### -->
  <!-- #################################################################################### -->
  <script>
  import { mapMutations, mapState } from "vuex";
  import moment from "moment";
  
  export default {
    name: "Solicitudes",
    data() {
      return {
        alert: false,
        rangoFechas: '',
        menuFechaInicio: false,
        menuFechaFinal: false,
        filtro: {
          fechaInicio: null,
          fechaFin: null,
        },
        tablaDespachos: {
          items: [],
          loading: false,
          headers: [
            { text: "FECHA DESPACHO", value: 'fechaDespacho', width: '5rem', align: "left", sortable: false },
            { text: "NÚMERO SOLICITUD", value: 'numeroSolicitud', align: "center", sortable: false },
            { text: "TIPO DOCUMENTO", value: 'tipoDocumento', align: "center", sortable: false },
            { text: "DOCUMENTO", value: 'numeroDocumento', align: "left", sortable: false },
            { text: "NOMBRE PACIENTE", value: 'nombrePaciente', sortable: false },
            { text: "EPS", value: 'EPS', align: "center", sortable: false },
            { text: "RÉGIMEN", value: 'nombreRegimen', align: "center", sortable: false },
            { text: "DIAGNÓSTICO", value: 'nombreDiagnostico', sortable: false },
            { text: "NÚMERO INGRESO", value: 'numeroIngreso', align: "center", sortable: false },
            { text: "FECHA INGRESO", value: 'fechaIngreso', align: "center", sortable: false },
            { text: "NÚMERO CAMA", value: 'numeroCama', align: "center", sortable: false },
            { text: "CÓDIGO BODEGA", value: 'codigoBodega', align: "center", sortable: false },
            { text: "NOMBRE BODEGA", value: 'nombreBodega', align: "center", sortable: false },
            { text: "CÓDIGO COHAN", value: 'codigoCohan', align: "center", sortable: false },
            { text: "CÓDIGO GENÉRICO", value: 'codigoGenerico', align: "center", sortable: false },
            { text: "LOTE", value: 'lote', align: "center", sortable: false },
            { text: "DESCRIPCIÓN CÓDIGO GENÉRICO", value: 'descripcionCodigoGenerico', align: "center", sortable: false },
            { text: "UBICACIÓN DESPACHO", value: 'nombreUbicacionDespacho', align: "center", sortable: false },
            { text: "DESPACHADO POR", value: 'despachadoPor', align: "center", sortable: false },
            { text: "CANTIDAD SOLICITADA", value: 'cantidadSolicitada', align: "center", sortable: false },
            { text: "CANTIDAD DESPACHADA", value: 'cantidadDespachada', align: "center", sortable: false },
            { text: "CANTIDAD PENDIENTE", value: 'cantidadPendiente', align: "center", sortable: false },
            { text: "DOSIS", value: 'dosis', align: "center", sortable: false },
            { text: "DURACIÓN MEDICAMENTO", value: 'duracionMedicamento', align: "center", sortable: false },
            { text: "FRECUENCIA", value: 'frecuencia', align: "center", sortable: false },
            { text: "PRESENTACIÓN", value: 'presentacion', align: "center", sortable: false },
            { text: "VÍA ADMINISTRACIÓN", value: 'viaAdministracion', align: "center", sortable: false },
            { text: "UNIDAD MEDIDA", value: 'unidadMedida', align: "center", sortable: false },
            { text: "FECHA VENCIMIENTO", value: 'fechaVencimiento', align: "center", sortable: false },
            { text: "REMISIÓN", value: 'remision', align: "center", sortable: false },
            { text: "DESCRIPCIÓN MOTIVO", value: 'descripcionMotivo', align: "center", sortable: false },
        ],
          footerProps: {
          'items-per-page-options': [10, 20, 30, 40, 50],
          'items-per-page-text': 'Items por página:',
          'show-current-page': true,
          'show-first-last-page': true
          },
        itemsPerPage: 10,
        page: 1,
        totalPage: 0,
        },
        rules: {
          required: [
            v => !!v || "Este campo es requerido.",
          ],
        },
      }
    },
    mounted() {
      this.listarDespachos();
    },
    computed: {
      ...mapState(["auth", "notify", "busy"]),
      /**
       * Función computada que devuelve la fecha actual en formato ISO (YYYY-MM-DD)
       * para que en el v-date-picker no seleccione fechas posteriores a las del día actual. 
       */
      currentDate() {
        return new Date().toISOString().split('T')[0];
      },
      /**
       * Valida que los filtros estén llenos y hayan resultados para permitir generar el reporte.
       */
      permitirGenerarReporte() {
        return this.filtro.fechaInicio && this.filtro.fechaFin && !this.rangoFechas && this.tablaDespachos.items.length > 0;
      }
    },
    watch: {
      /**
       * Este watcher se activa cuando alguna propiedad del objeto `filtro` cambia.
       * Es "deep", por lo que observa cambios en propiedades anidadas dentro de `filtro`.
       * Al detectar cambios, se encarga de desactivar la alerta (`alert` se establece en false).
       */
      'filtro.fechaInicio' : function () {
        if ((this.filtro.fechaInicio && this.filtro.fechaFin) || (!this.filtro.fechaInicio && !this.filtro.fechaFin)) {
          this.tablaDespachos.page = 1;
          this.listarDespachos();
        }
      },
      'filtro.fechaFin' : function () {
        if ((this.filtro.fechaInicio && this.filtro.fechaFin) || (!this.filtro.fechaInicio && !this.filtro.fechaFin)) {
          this.tablaDespachos.page = 1;
          this.listarDespachos();
        }
      },
      'tablaDespachos.page': function () {
        this.listarDespachos();
      },
      'tablaDespachos.itemsPerPage': function () {
        this.tablaDespachos.page = 1;
        this.listarDespachos(); 
      }
    },
    methods: {
        ...mapMutations([
            "updateAuth",
            "hideNotify",
            "showSuccess",
            "showBusy",
            "hideBusy",
        ]),
        /**
         *  Método que lista todos los despachos filtrando por fecha solicitudes en la tabla.
         */
        listarDespachos() {
            if (this.rangoFechas === '') {
                this.$http.get(`msa-reports/api/v1/reportes-intrahospitalario/listar-despachos`, {
                    params:{
                        fechaInicial: this.filtro.fechaInicio ? this.filtro.fechaInicio : null,
                        fechaFinal: this.filtro.fechaFin ? this.filtro.fechaFin : null,
                        page: this.tablaDespachos.page - 1,
                        size: this.tablaDespachos.itemsPerPage
                    }
                }).then(response => {
                    console.log(response)
                    this.tablaDespachos.items = response.data.content;
                    this.tablaDespachos.totalPage = response.data.totalElements;          
                }).catch(error => {
                    console.log(error);
                })
            }
        },
        /**
         * Este método verifica que la fecha inicial (`fechaUno`) sea anterior a la fecha final (`fechaDos`). Además, 
         * se asegura de que el rango de fechas seleccionadas no exceda un mes. Si alguna de estas condiciones no se cumple, 
         * establece un mensaje de error correspondiente y marca el estado como inválido (`invalid = true`).
         */
        validacionesFechas() {
            const fechaUno = new Date(`${this.filtro.fechaInicio}T00:00:00-05:00`);
            const fechaDos = new Date(`${this.filtro.fechaFin}T00:00:00-05:00`);
    
            if (fechaUno > fechaDos) {
            this.rangoFechas = 'La fecha final debe ser mayor a la fecha inicial';
            return;
            }
    
            let rangoUnMes = new Date(fechaUno);
            rangoUnMes.setMonth(rangoUnMes.getMonth() + 1);
    
            if (fechaUno.getDate() === 1) {
            rangoUnMes.setDate(0);
            }
    
            if (fechaDos > rangoUnMes) {
            this.rangoFechas = 'Las fechas seleccionadas no deben exceder un mes';
            } else {
            this.rangoFechas = '';
            }
        },
        /**
         * Método que guarda la fecha inicial en el campo de entrada.
         */
        saveFechaInicial() {
            this.$refs.menuFechaInicio.save(this.filtro.fechaInicio);
            this.validacionesFechas();
        },
        /**
         * Método que guarda la fecha final en el campo de entrada.
         */
        saveFechaFinal() {
            this.$refs.menuFechaFinal.save(this.filtro.fechaFin);
            this.validacionesFechas();
        },
        /**
         * Método que limpia el campo de entrada del filtro fecha inicial.
         */
        cleanFechaInicial() {
            this.filtro.fechaInicio = null;
            this.$refs.menuFechaInicio.save('');
            this.rangoFechas = '';
        },
        /**
         * Método que limpia el campo de entrada del filtro fecha final.
         */
        cleanFechaFinal() {
            this.filtro.fechaFin = null;
            this.$refs.menuFechaFinal.save('');
            this.rangoFechas = '';
        },
        /**
         * Método que formatea una fecha dada en el formato 'DD/MM/YYYY hh:mm:ss a'.
         * @param fecha - La fecha que se va a formatear.
         */
        formatoFecha(fecha) {
            return moment(fecha).format('DD/MM/YYYY hh:mm:ss a');
        },
        /**
         * Método para generar y descargar un archivo xlsx con los datos de las solicitudes de ruta.
         */
        generarReporte() {
            this.$http.get(`msa-reports/api/v1/reportes-intrahospitalario/reporte-despachos`, {
                responseType: 'blob', // Asegura que recibes el archivo como un blob
                params: {
                    fechaInicial: this.filtro.fechaInicio ? this.filtro.fechaInicio : null,
                    fechaFinal: this.filtro.fechaFin ? this.filtro.fechaFin : null,
                    page: 0,
                    size: this.tablaDespachos.totalPage
                }
            }).then(response => {
                // Crear un enlace de descarga para el archivo Excel
                const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                
                // Nombre del archivo con la extensión .xlsx
                const filename = `Despachos_${this.filtro.fechaInicio.replace(/-/g, '_')}_al_${this.filtro.fechaFin.replace(/-/g, '_')}.xlsx`;

                // Crear un enlace de descarga
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.setAttribute('download', filename);

                // Simular el click en el enlace para iniciar la descarga
                document.body.appendChild(link);
                link.click();
                
                // Limpiar el DOM
                document.body.removeChild(link);
            }).catch(error => {
                console.log(error);
            });
        }
    }
  }
  </script>
  <!-- #################################################################################### -->
  <!-- ###### Sección de Estilos                                                     ###### -->
  <!-- #################################################################################### -->
  <style scoped>
  .contenido {
    padding: 1rem;
    width: 100%;
    height: 86vh;
  }
  
  ::v-deep .elevation div table thead tr th {
    background-color: rgb(223, 223, 223) !important;
  }
  
  ::v-deep .elevation div table thead tr th span {
    font-weight: bold;
    color: black !important;
  }
  
  .filtro {
    width: 50%;
  }
  </style>
  